/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

import {
  _getDocumentLocale,
  _getProfileFieldAtKeyPath
} from './formatters-internal.js';

export function listNames(entityList) {
    if(!entityList) {
      return null;
    }
    let names = [];
    entityList.forEach(element => names.push(element.name));
    return names;
  }

export function betterTime(stamp) {
  const offset = new Date(stamp).getTimezoneOffset() / 60;
  const offsetStr = (offset < 0 ? '+0' : '-0') + Math.abs(offset) + ':00';
  return new Date(stamp + offsetStr);
}

export function dateRange1(
  profile,
  key = 'time',
  dateFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
    // hour12: true,
  },
  separator = '-') {

  const dateField = profile[key];
  if (!dateField) {
    console.error('could not find ', key, profile);
    return null;
  }

  if (!(dateField.start || dateField.end)) {
    console.error(key, 'is empty', profile);
    return null;
  }

  const locale = _getDocumentLocale();
  const start = betterTime(dateField.start);
  const end = betterTime(dateField.end);
  const startString = start.toLocaleString(locale, dateFormatOptions);
  let endString = end.toLocaleString(locale, dateFormatOptions);

  if (startString && endString) {
    // if (start.toLocaleDateString() === end.toLocaleDateString()) {
    //   endString = end.toLocaleString(locale, {
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     hour12: true,
    //   });
    // }

    return `${startString}`;
  }

  if (startString) {
    return startString;
  }

  return endString;
}


export function longDate(profile, keyPath) {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return '';
  }
  const parsedDateString = dateString + 'T00:00:00';
  const date = new Date(parsedDateString);
  const locale = _getDocumentLocale();
  if (!dateString) {
    return '';
  } else {
    return {
      day: date.toLocaleString(locale, {day: 'numeric'}),
      month: date.toLocaleString(locale, { month: 'long'}),
      year: date.toLocaleString(locale, {year: 'numeric'}),
    }
  }
}