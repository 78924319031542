export function setupAdobeDtm() {
  const adobeDtmEl = document.querySelector('#js-adobedtm');
  if (!adobeDtmEl) return;
  if (HitchhikerJS.isStaging) {
    // workaround for jambo issues while looking into whether newer versions have this fixed 
    // relevant discussion here: https://yext.slack.com/archives/C02UVSE7P6W/p1682608921752889?thread_ts=1682445297.061689&cid=C02UVSE7P6W
    const scriptOpenTag = ['<','s','c','r','i','p','t'].join('');
    const scriptCloseTag = ['<','/','s','c','r','i','p','t','>'].join('');
    adobeDtmEl.innerHTML = `
      ${scriptOpenTag} src="https://assets.adobedtm.com/4c90c16c5214/a63997b151ef/launch-EN07ee5eb20b18452894e6bfe08f061bd3-development.min.js" async>${scriptCloseTag}
    `;
  } else {
    adobeDtmEl.innerHTML = `
      ${scriptOpenTag} src="https://assets.adobedtm.com/4c90c16c5214/a63997b151ef/launch-ENf43030c5d9ac432eb0b5891826b6df29.min.js" async>${scriptCloseTag}
    `;
  }
}